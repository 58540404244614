import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { singOut } from "../../store/slices/userSlice";
import { useNavigate } from "react-router-dom";
import { singOutCompany } from "../../store/slices/companySlice";

const SignOut = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const goToLogin = () => {
    return navigate(`/login`);
  };

  useEffect(() => {
    dispatch(singOut());
    dispatch(singOutCompany());
    goToLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default SignOut;
