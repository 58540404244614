import store from "../store/store";
import { encryptText } from "../utils/encryptText/encryptText";

export const sendMsgFirstProvider = async (phoneNumbers, message, navigate) => {
  const baseApi = process.env.REACT_APP_API_BASE;
  const storeRedux = store.getState();
  const user = storeRedux?.user?.user;
  const company = storeRedux?.company?.company;

  const hashedPhoneNumbers = encryptText(JSON.stringify(phoneNumbers));
  const hashedMessage = encryptText(JSON.stringify(message));

  const options = {
    method: "POST", // specifying the HTTP method
    headers: {
      "Content-Type": "application/json", // specifying the Content-Type of the request body
      Authorization: `Bearer ${user?.token}`,
    },
    body: JSON.stringify({
      phoneNumbers: hashedPhoneNumbers,
      message: hashedMessage,
      companyId: company.id,
    }), // converting the data object to JSON string
  };

  // Performing the fetch request
  const response = await fetch(`${baseApi}msg/firstProvider`, options)
    .then((response) => {
      // Parse the response JSON if necessary
      return response.json();
    })
    .then((data) => {
      // Process the data returned by the server
      return data;
    })
    .catch((error) => {
      // Handle any errors that occurred during the fetch request
      console.error("Fetch error:", error);
      return { success: false };
    });

  if (response?.token === false) {
    console.log("token false");
    navigate(`/sign-out`);
  }

  return response;
};

export const getQuoteRemaining = async (navigate) => {
  const baseApi = process.env.REACT_APP_API_BASE;
  const storeRedux = store.getState();
  const user = storeRedux?.user?.user;
  const company = storeRedux?.company?.company;

  if (!company?.id) return {};

  const options = {
    method: "GET", // specifying the HTTP method
    headers: {
      "Content-Type": "application/json", // specifying the Content-Type of the request body
      Authorization: `Bearer ${user?.token}`,
    },
  };

  // Performing the fetch request
  const response = await fetch(
    `${baseApi}msg/getQuoteRemaining?companyId=${company.id}`,
    options
  )
    .then((response) => {
      // Parse the response JSON if necessary
      return response.json();
    })
    .then((data) => {
      // Process the data returned by the server
      return data;
    })
    .catch((error) => {
      // Handle any errors that occurred during the fetch request
      console.error("Fetch error:", error);
      return { success: false };
    });

  if (response?.token === false) {
    navigate(`/sign-out`);
  }

  return response;
};
