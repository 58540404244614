import React, { useEffect, useState } from "react";
import { phoneNumberPattern } from "../../utils/regex/regex";
import {
  getQuoteRemaining,
  sendMsgFirstProvider,
} from "../../services/notificationServer";
import ButtonInfo from "../buttonInfo/ButtonInfo";
import LoadingButton from "../loadingButton/LoadingButton";
import { useNotifications } from "../../context/Notifications";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Msg = () => {
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [phoneNumbersText, setPhoneNumbersText] = useState([]);
  const [message, setMessage] = useState("");
  const [validPhoneNumber, setValidPhoneNumber] = useState(false);
  const [moreThanQuote, setMoreThanQuote] = useState(false);
  const [quoteRemaining, setQuoteRemaining] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [sentMessages, setSentMessages] = useState({
    sent: 0,
    unSent: 0,
  });
  const { addNotification } = useNotifications();
  const navigate = useNavigate();
  const { company } = useSelector((state) => state.company);

  const getQuoteRemainingFront = async () => {
    const response = await getQuoteRemaining(navigate);
    if (response.success) {
      setQuoteRemaining(response.response.quotaRemaining);
    } else {
      setQuoteRemaining(0);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    if (!validPhoneNumber) {
      const response = await sendMsgFirstProvider(
        phoneNumbers,
        message,
        navigate
      );
      if (response.success) {
        setSentMessages(() => {
          return {
            sent: response.response.messageSuccess,
            unSent: response.response.messageField,
          };
        });
        setPhoneNumbers([]);
        setMessage("");
        setPhoneNumbersText("");
        if (response.response.messageField === 0) {
          addNotification({
            id: Date.now(),
            message: "Messages sent it!",
            kind: "success",
          });
        } else {
          addNotification({
            id: Date.now(),
            message:
              "Some message were not send, please check phone numbers and the message.",
            kind: "warning",
          });
        }
      } else {
        addNotification({
          id: Date.now(),
          message: "Somethings is wrong!",
          kind: "danger",
        });
      }
    }
    await getQuoteRemainingFront();
    setIsLoading(false);
  };

  const handleOnchangePhoneNumbers = (event) => {
    const text = event.target.value;
    const filteredValue = text.replace(/[^0-9.\-\s]/g, "");
    setPhoneNumbersText(filteredValue);
    const linesByEnter = filteredValue?.includes("\n")
      ? filteredValue.split("\n").filter((number) => number !== "")
      : [filteredValue];
    if (linesByEnter?.length > 0 && text) {
      const removedSpaces = linesByEnter.map((number) =>
        number.replace(/\s/g, "").replace(".", "").replace("-", "")
      );

      setPhoneNumbers(removedSpaces);

      const validateByEnter = removedSpaces.map((number) =>
        phoneNumberPattern.test(number)
      );
      const isInvalidFormat = validateByEnter.some((value) => value === false);
      setValidPhoneNumber(isInvalidFormat);

      if (removedSpaces.length > quoteRemaining) {
        setMoreThanQuote(true);
      } else {
        setMoreThanQuote(false);
      }
    } else {
      setValidPhoneNumber(false);
      setPhoneNumbers([]);
    }
  };

  const handleOnChanceMessage = (event) => {
    event.preventDefault();
    setMessage(event.target.value);
  };

  useEffect(() => {
    getQuoteRemainingFront();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="mb-6">
          <label
            htmlFor="message"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Message
          </label>
          <textarea
            id="message"
            rows="4"
            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Type..."
            aria-describedby="helper-message-explanation"
            value={message}
            required
            onChange={handleOnChanceMessage}
          ></textarea>
        </div>

        <div className="mb-6">
          <label
            htmlFor="phoneNumber"
            className={
              validPhoneNumber || moreThanQuote
                ? "block mb-2 text-sm font-medium text-red-700 dark:text-red-500"
                : "block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            }
          >
            Phone Number(s) - {phoneNumbers.length}
          </label>
          <textarea
            id="phoneNumber"
            rows="4"
            className={
              validPhoneNumber || moreThanQuote
                ? "bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg focus:ring-red-500 dark:bg-gray-700 focus:border-red-500 block w-full p-2.5 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500"
                : "block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            }
            placeholder={'Type phone numbers... (you can split by "enter")'}
            aria-describedby="helper-phone-number-explanation"
            value={phoneNumbersText}
            required
            onChange={handleOnchangePhoneNumbers}
          ></textarea>
          {!validPhoneNumber || (
            <p className="mt-2 text-sm text-red-600 dark:text-red-500">
              <span className="font-medium">Invalid Format!</span>
            </p>
          )}
          {!moreThanQuote || (
            <p className="mt-2 text-sm text-red-600 dark:text-red-500">
              <span className="font-medium">
                Do not have enough quota to send messages
              </span>
            </p>
          )}

          <br />
          <div className="flex gap-2">
            <p
              id="helper-phone-number-explanation-0"
              className="mt-2 text-sm text-gray-500 dark:text-gray-400"
            >
              Format allowed:
            </p>
            <ButtonInfo />
            <span className="inline-flex items-center bg-green-100 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded-full dark:bg-green-900 dark:text-green-300">
              <span className="w-2 h-2 me-1 bg-green-500 rounded-full"></span>
              Sent: {sentMessages.sent}
            </span>
            <span className="inline-flex items-center bg-red-100 text-red-800 text-xs font-medium px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300">
              <span className="w-2 h-2 me-1 bg-red-500 rounded-full"></span>
              Unsent: {sentMessages.unSent}
            </span>
          </div>
        </div>

        <div className="flex justify-end gap-2">
          <kbd className="px-2 py-2.5 text-xs font-semibold text-gray-800 bg-gray-100 border border-gray-200 rounded-lg dark:bg-gray-600 dark:text-gray-100 dark:border-gray-500">
            Quota Remaining: {quoteRemaining}
          </kbd>
          {isLoading ? (
            <LoadingButton text={"Sending ..."} />
          ) : (
            <button
              type="submit"
              disabled={validPhoneNumber || !message}
              className={
                validPhoneNumber || !message || phoneNumbers.length === 0
                  ? "text-white bg-blue-400 dark:bg-blue-500 cursor-not-allowed font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                  : "text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              }
            >
              Send Message(s)
            </button>
          )}
        </div>
      </form>
    </>
  );
};

export default Msg;
