//import { Navigate, Outlet, createBrowserRouter } from "react-router-dom";
import { Navigate, createBrowserRouter } from "react-router-dom";
import Login from "../components/login/Login";
import App from "../App";
import Chat from "../components/chat/Chat";
import Msg from "../components/msg/Msg";
import store from "../store/store";
import SingOut from "../components/SignOut/SignOut";

const ProtectedRoute = ({ children }) => {
  const isLogged = store.getState().user.isLogged;

  if (!isLogged) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

/*
const ProtectedRoutePermission = ({
  isAllowed,
  redirectPath = "/",
  children,
}) => {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};
*/

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <App />
      </ProtectedRoute>
    ),
    children: [
      {
        path: "",
        element: (
          <ProtectedRoute>
            <Msg />
          </ProtectedRoute>
        ),
      },
      {
        path: "chat",
        element: (
          <ProtectedRoute>
            <Chat />
          </ProtectedRoute>
        ),
      },
      {
        path: "msg",
        element: (
          <ProtectedRoute>
            <Msg />
          </ProtectedRoute>
        ),
      },
      {
        path: "sign-out",
        element: (
          <ProtectedRoute>
            <SingOut />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
]);
