import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getUserByUserAndPassword } from "../../store/thunks/userThunk";
import { useNotifications } from "../../context/Notifications";
import Alerts from "../alerts/Alerts";
import LoadingButton from "../loadingButton/LoadingButton";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLogged, isLoading } = useSelector((state) => state.user);
  const { notifications, removeNotification, addNotification } =
    useNotifications();

  const handleRemoveAlert = (id) => {
    removeNotification(id);
  };

  const [user, setUser] = useState({
    username: "",
    password: "",
  });

  const onSubmitUser = async (event) => {
    event.preventDefault();
    dispatch(
      getUserByUserAndPassword(user.username, user.password, addNotification)
    );
  };

  const onChangeUser = (event, name) => {
    const value = event.target.value;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const goToDashboard = () => {
    return navigate(`/`);
  };

  useEffect(() => {
    if (isLogged) {
      goToDashboard();
    }
  });

  return (
    <div className="flex justify-center items-center h-screen bg-white dark:bg-gray-800">
      {notifications.map((noti) => {
        return (
          <Alerts
            key={noti.id}
            id={noti.id}
            kind={noti.kind}
            text={noti.message}
            handleRemoveAlert={handleRemoveAlert}
          />
        );
      })}
      <form
        method="GET"
        className="max-w-sm mx-auto w-full"
        onSubmit={onSubmitUser}
      >
        <div className="flex justify-center mb-10">
          <img src="logo-OB.svg" className="h-32" alt="Gibt Logo" />
        </div>
        <div className="mb-5">
          <label
            htmlFor="username"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Your username
          </label>
          <input
            type="username"
            id="username"
            name="username"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Username"
            value={user.username}
            onChange={(e) => onChangeUser(e, "username")}
            required
          />
        </div>
        <div className="mb-5">
          <label
            htmlFor="password"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Your password
          </label>
          <input
            type="password"
            id="password"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Password"
            value={user.password}
            onChange={(e) => onChangeUser(e, "password")}
            required
          />
        </div>

        <div className="flex justify-end">
          {isLoading ? (
            <LoadingButton text={"login..."} />
          ) : (
            <button
              type="submit"
              disabled={isLoading}
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Log In
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default Login;
