import { configureStore } from "@reduxjs/toolkit";
import { userSlice } from "./slices/userSlice";
import { companySlice } from "./slices/companySlice";

export default configureStore({
  reducer: {
    user: userSlice.reducer,
    company: companySlice.reducer,
  },
});
