import { io } from "socket.io-client";

const baseApi = process.env.REACT_APP_API_SOCKET;

export const socket = io(baseApi, {
  autoConnect: true,
  auth: {
    serverOffset: 0,
    //username: "",
    //token: "",
  },
});
