import React from "react";
import { useNotifications } from "./context/Notifications";
import Alerts from "./components/alerts/Alerts";
import Nav from "./components/nav/Nav";
import { Outlet } from "react-router";

const App = () => {
  const { notifications, removeNotification } = useNotifications();

  const handleRemoveAlert = (id) => {
    removeNotification(id);
  };

  return (
    <>
      {notifications.map((noti) => {
        return (
          <Alerts
            key={noti.id}
            id={noti.id}
            kind={noti.kind}
            text={noti.message}
            handleRemoveAlert={handleRemoveAlert}
          />
        );
      })}
      <div className="bg-white dark:bg-gray-800 w-screen h-screen overflow-scroll">
        <Nav />
        <div className="p-10">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default App;
